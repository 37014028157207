<template>
  <div class="container">
    <div class="p-grid nested-grid">
      <div class="p-col-4 p-offset-4">
        <Card class="centered">
          <template #title> Giriş </template>
          <template #content>
            <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
              <div class="p-field p-mb-4">
                <div class="p-float-label">
                  <InputText id="username" v-model="v$.username.$model"
                    :class="{ 'p-invalid': v$.username.$invalid && submitted }" />
                  <label for="username" :class="{ 'p-error': v$.username.$invalid && submitted }">Kullanici Adı
                    *</label>
                </div>
                <small v-if="(v$.username.$invalid && submitted) ||
              v$.username.$pending.$response
              " class="p-error">{{
              v$.username.required.$message.replace("Value", "Name")
            }}</small>
              </div>
              <div class="p-field p-mt-4">
                <div class="p-float-label">
                  <Password id="password" v-model="v$.password.$model"
                    :class="{ 'p-invalid': v$.password.$invalid && submitted }" :feedback="false" />
                  <label for="password" :class="{ 'p-error': v$.password.$invalid && submitted }">Şifre *</label>
                </div>
                <small v-if="(v$.password.$invalid && submitted) ||
              v$.password.$pending.$response
              " class="p-error">{{
              v$.password.required.$message.replace("Value", "Password")
            }}</small>
              </div>
             
              <Button type="submit" label="Giriş" class="p-mt-2" />
            </form>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";


export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      showMessage: false,

      showRecaptcha: false,
      loadingTimeout: 30000 // 30 seconds
    };
  },
  validations() {
    return {
      username: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
      password: {
        required: helpers.withMessage("Bu alan boş bırakılamaz", required),
      },
    };
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        this.Login();

        return;
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Warn Message",
          detail: "Message Content",
          life: 3000,
        });
      }
    },
    Login() {
      let user = {
        username: this.username,
        password: this.password,
      };
      this.$store.dispatch("auth/login", user).then(() => {
        // Role göre yönlendirme yapılmalı
        this.$router.push({ path: "/Admin/Main" });
      },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$toast.add({
            severity: "warn",
            summary: "Giriş Hatası",
            detail: this.message,
            life: 3000,
          });
        }
      );
    },
    resetForm() {
      this.username = "";
      this.password = "";
      this.submitted = false;
    },
  },
  components: {

  }
};
</script>
<style scoped>
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #cdd4cd;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
</style>
